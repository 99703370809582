<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('Banners - Advertisers') }}
    </p>
    <router-link :to="{name: 'SCAds'}" tag="a" class="primary-btn cancel-btn color-white" exact>
      {{ $t('Back to banners') }}
    </router-link>
    <div class="weight-700 d-flex align-items-center filter-part">
      <div class="filter-item advertiser-option mb-5">
        <label class="" for>{{ $t('Status') }}</label>
        <vue-select
          :options="filter.advertiser.options"
          class="input cus-select filter-dropdown advertiser-option"
          v-model="filter.advertiser.selected"
        ></vue-select>
      </div>
      <div class="date-selector">
        <div class="d-flex justify-content-between align-items-center">
          <label class="" for>{{ $t('Start Date') }}: </label>
          <div class="d-flex align-items-center p-relative">
            <Datepicker
              v-model="filter.start_date"
              :format="format"
              class="input cus-datepicker"
              :language="languages[currentLang]"
            ></Datepicker>
            <img src="/assets/img/icons/calendar-alt-light.svg" alt="" class="calendar-img" />
          </div>
        </div>
      </div>
    </div>

    <div class="table-content-part">
      <div class="d-flex justify-content-between">
        <div class="search-bar">
          <p class="search-label">{{ $t('Search') }}</p>
          <v-text-field
            v-model="search"
            single-line
            hide-details
            class="search-form"
          ></v-text-field>
        </div>
      </div>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="ads_data"
        class="cus-table editable-table main-table striped-table"
        item-key="id"
        :loading="loading"
      >
        <template v-slot:[`item.status`]="{ item }">
          <div class="ticket-item bg-green1" v-if="item.status == 'פעיל'">
            {{ item.status }}
          </div>
          <div class="ticket-item bg-3" v-else>
            {{ item.status }}
          </div>
        </template>
        <template v-slot:[`item.detail_editing`]="{ }">
          <img src="/assets/img/icons/edit.svg" alt="" class="edit-icon" />
        </template>
      </v-data-table>
    </div>

    <div class="action-part">
      <button class="primary-btn new-btn" @click="openCreateModal()">
        {{ $t('New publisher') }}
      </button>
    </div>

     <!-- ----- modal ----------- -->
    <modal
      :width="1400"
      :adaptive="true"
      class="createModal-adsadver CEModal modal-scroll-bar"
      name="createModal"
    >
      <h4 class="weight-700">{{ $t("New publisher / editor") }}</h4>
      <div class="form-block mt-5">
        <div class="mb-5 px-3 w-100 m-publication_input">
          <label>{{ $t('Name of publication') }}</label>
          <input type="text" class="input">
        </div>
        <div class="mb-5 px-3 w-100 m-dealer_input">
          <label>{{ $t('H.P. / Authorized Dealer') }}</label>
          <input type="text" class="input">
        </div>
        <div class="mb-5 px-3 w-100 m-status_input">
          <label>{{ $t('Status') }}</label>
          <vue-select
            :options="filter.advertiser.options"
            class="input cus-select"
            v-model="filter.advertiser.selected"
          ></vue-select>
        </div>
        <div class="mb-5 px-3 w-100 m-site_input">
          <label>{{ $t('Site') }}</label>
          <input type="text" class="input">
        </div>
        <div class="mb-5 px-3 w-100 m-estdate_input">
          <label>{{ $t('Date of establishment') }}</label>
          <input type="text" class="input bg-grey2" value="18/10/2020">
        </div>
      </div>

      <div class="d-flex justify-content-between px-3">
        <div class="google-address-block">
          <label for="">{{ $t('Address') }} ({{ $t('From Google') }})</label>
          <input type="text" class="input mb-5">
          <input type="text" class="input">
        </div>
        <div class="d-flex address-block">
          <div class="address-input-box">
            <label for="">{{ $t('Contacts') }}</label>
            <input type="text" class="input mb-5">
            <input type="text" class="input mb-2">
            <button class="primary-btn primary-btn_sm m-0">{{ $t('Add') }}</button>
          </div>
          <div class="added-list-block">
            <label for="">{{ $t('Phones') }}</label>
            <div class="address-list">
              <div class="list-item">
                <div class="d-flex align-items-center">
                  <button class="blue-close_btn">
                    <img src="/assets/img/icons/times-light.svg" alt="">
                  </button>
                  <span>test</span>
                </div>
                <p>053-54545534</p>
              </div>
              <div class="list-item">
                <div class="d-flex align-items-center">
                  <button class="blue-close_btn">
                    <img src="/assets/img/icons/times-light.svg" alt="">
                  </button>
                  <span>test</span>
                </div>
                <p>053-54545534</p>
              </div>
              <div class="list-item">
                <div class="d-flex align-items-center">
                  <button class="blue-close_btn">
                    <img src="/assets/img/icons/times-light.svg" alt="">
                  </button>
                  <span>test</span>
                </div>
                <p>053-54545534</p>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex address-block">
          <div class="address-input-box">
            <label for="">{{ $t('Accountants') }}</label>
            <input type="text" class="input mb-5">
            <input type="text" class="input mb-2">
            <button class="primary-btn primary-btn_sm m-0">{{ $t('Add') }}</button>
          </div>
          <div class="added-list-block">
            <label for="">{{ $t('Telephone books') }}</label>
            <div class="address-list">
              <div class="list-item">
                <div class="d-flex align-items-center">
                  <button class="blue-close_btn">
                    <img src="/assets/img/icons/times-light.svg" alt="">
                  </button>
                  <span>test</span>
                </div>
                <p>053-54545534</p>
              </div>
              <div class="list-item">
                <div class="d-flex align-items-center">
                  <button class="blue-close_btn">
                    <img src="/assets/img/icons/times-light.svg" alt="">
                  </button>
                  <span>test</span>
                </div>
                <p>053-54545534</p>
              </div>
              <div class="list-item">
                <div class="d-flex align-items-center">
                  <button class="blue-close_btn">
                    <img src="/assets/img/icons/times-light.svg" alt="">
                  </button>
                  <span>test</span>
                </div>
                <p>053-54545534</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-between px-3 mt-15">
        <div class="bank-block">
          <p class="weight-700 fs18 text-decoration-underline text-center mb-5">{{ $t('Bank account information') }}</p>
          <div class="d-flex mb-5">
            <div class="w-60">
              <label for="">{{ $t('Bank') }}</label>
              <input type="text" class="input">
            </div>
            <div class="mx-10 w-40">
              <label for="">{{ $t('Bank number') }}</label>
              <input type="text" class="input">
            </div>
          </div>
          <div class="d-flex mb-5">
            <div class="w-60">
              <label for="">{{ $t('Account Number') }}</label>
              <input type="text" class="input">
            </div>
            <div class="mx-10 w-40">
              <label for="">{{ $t('Branch') }}</label>
              <input type="text" class="input">
            </div>
          </div>
        </div>
        <div class="credit-block">
          <p class="weight-700 fs18 text-decoration-underline text-center mb-5">{{ $t('Credit details') }}</p>
          <div class="d-flex justify-content-between mb-5">
            <div class="lg-div">
              <label for="">{{ $t("Cardholder's name") }}</label>
              <input type="text" class="input">
            </div>
            <div class="lg-div">
              <label for="">{{ $t('Ticket Number') }}</label>
              <input type="text" class="input">
            </div>
            <div class="sm-div">
              <label for="">{{ $t('3 digits on the back of the card') }}</label>
              <input type="text" class="input">
            </div>
          </div>
          <div class="d-flex justify-content-between mb-5">
            <div class="lg-div">
              <label for="">{{ $t('ID') }}</label>
              <input type="text" class="input">
            </div>
            <div class="lg-div">
              <label for="">{{ $t('Valid until') }}</label>
              <div class="d-flex justify-content-between w-100">
                <vue-select
                  :options="filter.advertiser.options"
                  class="input cus-select"
                  v-model="filter.advertiser.selected"
                ></vue-select>
                <vue-select
                  :options="filter.advertiser.options"
                  class="input cus-select"
                  v-model="filter.advertiser.selected"
                ></vue-select>
              </div>
            </div>
            <div class="sm-div">
            </div>
          </div>
        </div>
      </div>

      <div class="px-3 mt-10">
        <label for="">{{ $t("List of advertisements and prices") }}</label>
        <div class="bdbr-2 w-100 bg-white">
          <v-data-table
            :headers="adverPriceTableHeaders"
            :items="adsPrice_data"
            class="cus-table editable-table main-table striped-table"
            item-key="id"
            :loading="loading"
          >
            <template v-slot:[`item.price`]="{ item }">
              <span class="color-blue1" v-if="item.payment_status=='שולם'">{{ item.price }}</span>
              <span class="color-red4" v-else>{{ item.price }}</span>
            </template>
            <template v-slot:[`item.payment_status`]="{ item }">
              <span class="color-blue1" v-if="item.payment_status=='שולם'">{{ item.payment_status }}</span>
              <span class="color-red4" v-else>{{ item.payment_status }}</span>
            </template>
            <template v-slot:[`item.detail_editing`]="{ }">
              <v-icon medium color="dark">edit</v-icon>
            </template>
          </v-data-table>
          <p class="total-price">
            <b>{{ $t("Total") }}: <span class="color-red4">25,000</span></b>
          </p>
        </div>
      </div>

      <div class="d-flex align-items-center mt-15">
        <button 
          class="primary-btn primary-btn_sm"
        >
          {{ $t("Save") }}
        </button>
        <button 
          class="primary-btn primary-btn_sm bg-red"
          @click="closeCreateModal()"
        >
          {{ $t("Cancelation") }}
        </button>
      </div>
    </modal>

    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main>
</template>

<script>
import 'bootstrap';
import '../../../common/plugins/bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { mapState, mapActions } from 'vuex';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  name: 'SCAdsAdvertisers',
  components: {
    Loading
  },
  computed: {
    ...mapState({
      loading: (state) => state.report.loading,
    }),
  },
  data() {
    return {
      search: '',
      headers: [
        {
          text: this.$t('There'),
          align: 'center',
          sortable: false,
          value: 'there',
        },
        {
          text: this.$t('Address'),
          align: 'center',
          sortable: false,
          value: 'address',
        },
        {
          text: this.$t('Contact'),
          align: 'center',
          sortable: false,
          value: 'contact',
        },
        {
          text: this.$t('Phones'),
          align: 'center',
          sortable: false,
          value: 'phones',
        },
        {
          text: this.$t('Responsible for the accounting'),
          align: 'center',
          sortable: false,
          value: 'responsible_accounting',
        },
        {
          text: this.$t('Accounting telephone'),
          align: 'center',
          sortable: false,
          value: 'accounting_telephone',
        },
        {
          text: this.$t('Payment Methods'),
          align: 'center',
          sortable: false,
          value: 'payment_methods',
        },
        {
          text: this.$t('Open_DEBT'),
          align: 'center',
          sortable: false,
          value: 'open_debt',
        },
        {
          text: this.$t('Status'),
          align: 'center',
          sortable: false,
          value: 'status',
        },
        {
          text: this.$t('A. Registration'),
          align: 'center',
          sortable: false,
          value: 'a_registration',
        },
        {
          text: this.$t('Bail'),
          align: 'center',
          sortable: false,
          value: 'bail',
        },
        {
          text: this.$t('Close charge'),
          align: 'center',
          sortable: false,
          value: 'close_charge',
        },
        {
          text: this.$t('Details and editing'),
          align: 'center',
          sortable: false,
          value: 'detail_editing',
        }
      ],
      adverPriceTableHeaders: [
        {
          text: this.$t('Date please'),
          align: 'center',
          sortable: false,
          value: 'datePlease',
        },
        {
          text: this.$t('Name of publication'),
          align: 'center',
          sortable: false,
          value: 'name',
        },
        {
          text: this.$t('Location'),
          align: 'center',
          sortable: false,
          value: 'location',
        },
        {
          text: this.$t('Banner Type'),
          align: 'center',
          sortable: false,
          value: 'type',
        },
        {
          text: this.$t('Language'),
          align: 'center',
          sortable: false,
          value: 'language',
        },
        {
          text: this.$t('A. Beginning'),
          align: 'center',
          sortable: false,
          value: 'beginning',
        },
        {
          text: this.$t("A. End"),
          align: 'center',
          sortable: false,
          value: 'end',
        },
        {
          text: this.$t('Days'),
          align: 'center',
          sortable: false,
          value: 'days',
        },
        {
          text: this.$t('Hours'),
          align: 'center',
          sortable: false,
          value: 'hours',
        },
        {
          text: this.$t('Price'),
          align: 'center',
          sortable: false,
          value: 'price',
        },
        {
          text: this.$t('Payment status'),
          align: 'center',
          sortable: false,
          value: 'payment_status',
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'detail_editing',
        }
      ],
      filter: {
        advertiser: {
          selected: { label: 'הכל', code: 0 },
          options: [{ label: 'הכל', code: 0 }],
        },
        start_date: ''
      },

      format: 'd MMMM yyyy',
      itemsPerPageOptions: [10, 15, 25, 50, 100],
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      ads_data: [
        {
          there: 'החברה המרכזית',
          address: 'המוסכים 3, חדרה',
          contact: 'פלוני אלמוני',
          phones: "054-5353745",
          responsible_accounting: 'דוד מזור',
          accounting_telephone: '08-6566423',
          payment_methods: 'הו"ק',
          open_debt: '15,000',
          status: 'פעיל',
          a_registration: '14/10/2020',
          bail: '10,000',
          close_charge: '14/10/2020',
          detail_editing: ''
        },
        {
          there: 'החברה המרכזית',
          address: 'המוסכים 3, חדרה',
          contact: 'פלוני אלמוני',
          phones: "054-5353745",
          responsible_accounting: 'דוד מזור',
          accounting_telephone: '08-6566423',
          payment_methods: 'הו"ק',
          open_debt: '15,000',
          status: 'מוקפא',
          a_registration: '14/10/2020',
          bail: '10,000',
          close_charge: '14/10/2020',
          detail_editing: ''
        }
      ],
      adsPrice_data: [
        {
          datePlease: '14/10/2020',
          name: 'פרסומת 1',
          location: 'ראשי - אמצעי',
          type: 'תמונה',
          language: 'ערבית',
          beginning: '14/10/2020',
          end: '25/10/2020',
          days: 'א, ב, ה, ש',
          hours: '14:00-16:00 19:00-21:00',
          price: '10,000',
          payment_status: 'שולם',
          detail_editing: '',
        },
        {
          datePlease: '14/10/2020',
          name: 'פרסומת 1',
          location: 'ראשי - אמצעי',
          type: 'תמונה',
          language: 'ערבית',
          beginning: '14/10/2020',
          end: '25/10/2020',
          days: 'א, ב, ה, ש',
          hours: '14:00-16:00 19:00-21:00',
          price: '25,000',
          payment_status: 'פתוח',
          detail_editing: '',
        }
      ],
      createModal: {
      }
    };
  },
  methods: {
     /* <!-- ------------create modal-------------- --> */
    openCreateModal() {
      this.$modal.show("createModal");
       $("html").css("overflow", "hidden");
    },
    closeCreateModal() {
      this.$modal.hide("createModal");
       $("html").css("overflow", "auto");
    },
  },
  mounted() {
    this.filter.start_date = new Date();
  },
};
</script>

<style lang="scss">
.cus-select {
  ul {
    padding-left: 0;
  }
  .vs__selected {
    font-weight: 300;
  }
}
.inline-checkbox .v-input__control {
  height: 100%;
}
.createModal-adsadver {
  .vm--modal {
    background-color: #f3f3f3;
    padding: 20px 40px;
    max-height: 95% !important;
    @media screen and (max-width: 480px) {
      padding: 20px 10px;
    }
    label {
      font-weight: 700;
      font-size: 16px;
    }
    .input {
      height: 36px;
      font-size: 16px;
    }
    .form-block {
      display: flex;
      .m-dealer_input {
        max-width: 280px;
      }
      .m-status_input {
        max-width: 200px;
      }
      .m-site_input {
        max-width: 300px;
      }
      .m-estdate_input {
        max-width:  150px;
      }
    }

    .google-address-block {
      max-width: 20%;
    }

    .address-block {
      max-width: 35%;
      .address-input-box {
        width: 30%;
        margin: 0 10px;
      }
      .added-list-block {
        width: 70%;
        .address-list {
          border: 1px solid #b2bbc2;
          background-color: #fff;
          padding: 0 10px;
          font-size: 15px;
          .list-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 26px;
            .blue-close_btn {
              margin-left: 10px;
            }
          }
        }
      }
    }

    .bank-block {
      max-width: 30%;
      label {
        font-weight: 100;
      }
    }

    .credit-block {
      max-width: 55%;
      label {
        font-weight: 100;
      }
      .lg-div {
        width: 35%;
      }
      .sm-div {
        width: 23%;
      }
      .cus-select {
        width: 47%;
      }
    }

    .total-price {
      margin: 0 30px 10px;
    }
  }
}


</style>
<style scoped lang="scss">
label, p {
  margin-bottom: 0;
}

.filter-part {
  margin: 20px 40px 0 40px;
  .filter-item {
    width: 100%;
    padding: 0 10px;
  }
  .input {
    height: 30px;
    padding: 0 10px;
    border-radius: 8px;
  }
  .calendar-img {
    width: 20px;
    position: absolute;
    right: 12px;
    top: 5px;
    left: unset;
  }
  .filter-dropdown {
    min-width: 170px;
    height: 40px;
    border: none;
    border-radius: 0;
  }
  .date-selector {
    min-width: 350px;
    padding: 0 10px;
    margin-top: 20px;
    margin-right: 30px;
    .cus-datepicker {
      max-width: 200px;
    }
  }
}

.main-table {
  .ticket-item {
    box-shadow: 0px 3px 6px #00000029;
    color: #fff;
    border-radius: 10px;
    width: 60px;
    margin: 0 auto;
    text-align: center;
    padding: 5px 0;
  }
}

.action-part {
  margin: 20px 50px 0 50px;
  display: flex;
  .primary-btn {
    border-radius: 10px;
    width: 200px;
    height: 44px;
  }
  .new-btn {
    background-color: #E2B629;
  }
}

.cancel-btn {
  position: absolute;
  background-color: #0D3856;
  left: 50px;
  top: 30px;
  border-radius: 10px;
  width: 200px;
}

.advertiser-option {
  max-width: 250px;
}
.rtl-type {
  .filter-part {
    .calendar-img {
      left: 12px;
      right: unset;
    }
  }
}

.ltr-type {
  .cancel-btn {
    left: unset;
    right: 50px;
  }
}

@media screen and (max-width: 1650px) {
  .filter-part {
    flex-wrap: wrap;
    .filter-item {
      max-width: 250px;
    }
  }
}
@media screen and (max-width: 1250px) {
  .createModal .vm--modal .form-block {
    flex-wrap: wrap;
      .m-publication_input {
        max-width: 480px;
      }
  }
}

@media screen and (max-width: 480px) {
  .action-part,
  .filter-part {
    margin: 20px 10px 0;
    .filter-item {
      max-width: 100%;
    }
  }
  .createModal .vm--modal {
    .form-block {
      .m-status_input,
      .m-estdate_input,
      .m-dealer_input,
      .m-site_input {
        max-width: unset;
      }
      .each-list > div {
        flex-wrap: wrap;
      }
      .wrap-480 {
        flex-wrap: wrap;
      }
    }
  }
}
</style>